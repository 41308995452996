body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "PoppinsSemi";
  src: local("PoppinsSemi"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
p{
  
 text-justify: auto;
}
h1,h2{
  
  letter-spacing: 5px;
  word-spacing: 10px;
}

h6{
  color: #000;

  letter-spacing: 5px;
  word-spacing: 10px;
}

.blue-color{
  color: #2766AE;;
}

.white-color{
  color: #fff;;
}
.black-color{
  color: #000;;
}
.grey-color{
  color: #555;;
}
.justify
{
  text-align: justify;
  text-justify: inter-word;
}
.bold
{
  font-weight: bold;
  font-size: 18px;
}

.justify-center
{ text-align: justify;
  text-align-last: center;
}

.fl
{
  float: left;
}
.fr
{
  float: right;
}
.italic
{
  font-style: italic;
}
ol>li
{
  font-weight: bold;
  color: #2766AE;
  margin-top: 10px;
  margin-bottom: 10px;
}
.al-left
{
  text-align: left;
}
.orange-color
{
  color: #f48320;
}
.tint-color
{
  color: #10777f;
}
.black-list li
{
  color: #000;
}
.btn-orange
{
  
  display: inline-block;
  padding: 0.2em 1em;
  margin: 0 0.1em 0.1em 0;
  border: 2px solid #f48320;
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #f48320;
  text-align: center;
  transition: all 0.2s;
  font-size: 18px;
  background-color: #fff;
}
.btn-orange:hover
{
  color: #fff;
  background-color: #f48320;
}

.btn-grey
{
  
  display: inline-block;
  padding: 0.2em 1em;
  margin: 0 0.1em 0.1em 0;
  border: 2px solid #4e4e4e;
  border-radius: 1.5em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #424242;
  text-align: center;
  transition: all 0.2s;
  font-size: 18px;
  background-color: #fff;
}
.btn-grey:hover
{
  color: #fff;
  background-color: #2c2c2c;
}

.brd
{
  border-radius: 15px;
}