.teamMain{
   
    padding: 20px;
}

.card-1
{
    background-color: #ddd;
    height: 170px;
    width: 170px;
    position: relative;
    margin-top: 10px;
}

.cimg
{
    margin-top: 20px;
    border-radius: 30%;
    border:5px solid #f0e2d7;
   
    padding: 10px;
}

.ctext{
    position: absolute;
}

.subp
{

}
.subcard
{
    margin-top: 20px;
    margin-bottom: 20px;
    width:81%;
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-bottom: 2px solid #f48320;    
}
