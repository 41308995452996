.servMain2
{
    
    background-size: 100% 100%;
    padding-top: 200px;
    padding-bottom: 200px;
}

.card1
{
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-top: 10px;
}