div{
    overflow-x: hidden;
}
.contactus{
    background-color: #ffffff; 
    border-radius: 20px;
    border:2px solid #aaa;
    max-width: 95%;
    width:500px
}

.cntct-2 h2
{
   color: #068DAB;
}
.smbn
{
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
/* .fbn
{
  position: fixed;
  bottom: 30px;
  right:20px;
  border-radius: 30px;
  border: none;
  background-color: rgb(0, 94, 255);
  width:45px;
  height: 45px;
  padding: 5px;
  border-radius: 10px;
} */

.fbn
{
    position: fixed;
    bottom: 28px;
    right:15px;
    background: none;
    border: none;
}
.msg-icon
{
    background-color: rgb(15, 96, 162);
    width:45px;
    height: 45px;
    padding: 5px;
    border-radius: 10px;
}
.cross
{
    background: none;
    border: none;
    color: #aaa;
}