@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap");

.body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: #ecf0f3;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  overflow: hidden;
  font-family: poppins;
}

.container0 {
  position: relative;
  width: 350px;
  height: 550px;
  border-radius: 20px;
  padding: 40px;
  box-sizing: border-box;
  background: #ecf0f3;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

.brand-logo {
  height: 100px;
  width: 100px;
  background-image: url("../../assets/logo-new.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
  text-align: center;
}

.brand-title {
  margin-top: 10px;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1647a8;
  letter-spacing: 1px;
}

.inp {
  text-align: left;
  margin-top: 30px;
}

.lbl,
.inp,
.bluebn {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.lbl {
  margin-bottom: 4px;
  text-align: left;
}

.lbl:nth-of-type(2) {
  margin-top: 12px;
}

.inp::placeholder {
  color: gray;
}

.inp {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.bluebn {
  color: white;
  margin-top: 20px;
  background: #1658a8;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 900;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
  transition: 0.5s;
}

.bluebn:hover {
  box-shadow: none;
}

.al {
  position: absolute;
  font-size: 8px;
  bottom: 4px;
  right: 4px;
  text-decoration: none;
  color: black;
  background: yellow;
  border-radius: 10px;
  padding: 2px;
}

.h1 {
  position: absolute;
  top: 0;
  left: 0;
}
