.ceocard
{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.cartoonCon
{

}

.fCol
{

    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(186, 186, 186, 0.2) 0px -3px 0px inset;
}
.bl-1
{
}
.teamimg
{
    object-fit: cover;
    height: 200px;
    width:150px;
    max-width: 100%;
}