.NavCon
{
 padding: 0px;
}

.carCon
{
    margin-top: 100px;
}

@media (min-width:320px)  {   .item   
    {
        height: 50vh;
        width: 100%;
        object-fit: cover;
    }
}
@media (min-width:961px)  { 
    .item   
    {
        height: 85vh;
        width: 100%;
        object-fit: cover;
    } }
@media (min-width:1281px) { 
    .item   
    {
        height: 85vh;
        width: 100%;
        object-fit: cover;
    }
 }