div
{
    overflow-x: hidden;
}

.footbar
{
    background-color: #444;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.f-logo
{
    width:30px
}
.ficn
{
    background-color: #333;
    width:40px;
    height: 40px;
    padding: 10px;
    border-radius: 20px;
}
.ficn2
{
    width:35px;
}
.footbar2
{
    background-color: #000;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    color: #aaa;
    padding: 5px;
}
.fttext
{
    color: #aaa;
}